"use client";
import {
  Footer,
  MainNav,
  DownloadApp,
  FAQs,
  Features,
  Guide,
  Testimonials,
  Hero,
  IWD as Iwd,
} from "src/components";
import { HeyWowFont } from "../fonts/fonts-config";

export default function Home({
  searchParams,
}: {
  searchParams?: { mobile?: string };
}) {
  const fromAppParams = searchParams?.mobile;

  return (
    <main className={HeyWowFont.className}>
      <MainNav />
      <Hero />
      <Features />
      <Guide />
      <Iwd />
      <FAQs />
      <DownloadApp fromApp={fromAppParams} />
      <Testimonials />
      <Footer />
    </main>
  );
}
